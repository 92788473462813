@import '../../../styles/_settings.scss';

.sign-header {
  h1 {
    color: $COLOR_TITLE;
  }

  .tooltip-info {
    color: $COLOR_TOOLTIP;
    cursor: pointer;
  }
}

.sign-ctn {
  background-color: $COLOR_BACKGROUND_MESSAGE;
  border: 1px solid $COLOR_BORDER_MESSAGE;
  border-radius: 5px;
  padding: 2rem;
  margin: 1em 0;
}

.signing-process-text {
  font-size: 1.2rem;
  color: #606A76;
}

#download-mandate {
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  color: #FC811B;

  .ant-spin-nested-loading {
    width: fit-content;
  }
}
