@import '../../../../styles/_settings.scss';

.summary-list {
  border: 1px solid #8FB0E1;
  padding: 0;
  margin-top: 2rem;
  border-radius: 10px;

  .title {
    color: $COLOR_BLACK;
    padding: 0.5rem 2rem;
    margin: 0;
    background: #E5ECF8 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0 0;

    .bank-icon {
      height: 2.5rem;
      margin-left: 0.5rem;
    }
  }

  .title-new {
    color: $COLOR_BLACK;
    padding: 0.5rem 2rem;
    margin: 0;
    background: #E5ECF8 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0 0;

    .bank-icon {
      height: 42px;
      margin-left: 0.5rem;
    }
  }

  .item-ctn {
    border-radius: 5px;

    .item {
      display: flex;
      border-radius: 10px;
      justify-content: space-between;
      background: #f8fbff 0% 0% no-repeat padding-box;
      margin: 1rem;
      padding: 1rem;

      &:last-child {
        border-bottom: none;
      }

      span {
        color: $COLOR_BLACK;
      }
    }
  }
}

.title-quantity {
  font-size: 1rem;
  color: $COLOR_PRIMARY;
  float: right;
  margin: 1rem;

  .icon-quantity {
    background-color: $COLOR_PRIMARY;
    border-radius: 50%;
    padding: 0.5rem;
    color: #fff;
    margin-right: 5px;
  }
}
