$break-xs: 575px; // screen < 576px
$break-sm: 576px; // screen ≥ 576px
$break-md: 768px; // screen ≥ 768px
$break-lg: 992px; // screen ≥ 992px
$break-xl: 1200px; // screen ≥ 1200px
$break-xxl: 1600px; // screen ≥ 1

$BASE_COLOR_6: rgba(0, 122, 183, 1);

.loading-alert {
  border: 1.5px solid antiquewhite;
  border-radius: 10px;
  padding: 0.5rem 0.5em;
  width: inherit;
  width: 80%;
  margin-bottom: 0.3rem;
  max-width: 450px;

  @media screen and (max-width: $break-xs) {
    width: fit-content;
    padding: 0.5em;
  }

  .ant-progress {
    max-width: 300px;
  }

  img.coffee-animation {
    width: 80%;
    margin-top: 10px;
  }

  p {
    color: $BASE_COLOR_6;
  }
}
