@import './kretix/_base';

$break-xxs: 568px;
$break-xs: 575px;
$break-sm: 576px;
$break-md: 578px;
$break-lg: 992px;
$break-xl: 1200px;
$break-xxl: 1600px;
$COLOR_PRIMARY: #0078C4;
$COLOR_SECONDARY: #0078C4;
$COLOR_TITLE: #616161;
$COLOR_TEXT_DISABLED: #6060609c;
$COLOR_GREY: #8D8D8D;
$COLOR_BLACK: #616161;
$COLOR_WHITE: #ffffff;

$COLOR_TOOLTIP: #FF7900;
$COLOR_STEPS: #0078C4;
$COLOR_BORDER_MESSAGE: #eaf4ff;
$COLOR_BACKGROUND_MESSAGE: #f8fbff;

$WIDTH_LOGO_HEADER: 170px;
$WIDTH_LOGO: 48px;
