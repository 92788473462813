.sign-documents-ctn {
  margin: 0 auto;
  border: 1px solid #917f7f21;
  border-radius: 10px;
  padding: 1rem;
  margin-top: 2rem;

  .checkbox-btn {
    margin: 2rem 0;
  }

  .ctn-btn {
    margin-bottom: 3rem;
    text-align: center;
  }

  .sign-documents-img {
    > img {
      width: 100%;
    }
  }
}
