@import '../../../styles/_settings.scss';

.new-banking-products-header {
  h1 {
    color: $COLOR_TITLE;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 1.3rem;
  }
}

.ctn-new-banking-products {
  margin-top: 1rem;
}
