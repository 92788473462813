$BASE_COLOR_4: rgba(84, 101, 118, 1);
$BASE_COLOR_6: rgba(0, 122, 183, 1);
$BASE_COLOR_10: rgba(223, 230, 238, 1);
$BASE_COLOR_11: rgba(125, 142, 160, 1);

$break-sm: 576px; // screen ≥ 576px

.security-and-access-modal {
  .ant-modal-content {
    border-radius: 10px;

    .ant-modal-header {
      border-radius: 10px;
    }

    .ant-modal-body {
      padding: 4em 4em 2em 4em;

      @media screen and (max-width: $break-sm) {
        padding: 3em 2em 2em 2em;
      }

      .text {
        color: $BASE_COLOR_4;
      }

      .ctn-info {
        margin-top: 2em;

        span {
          display: inline-block;
          margin-bottom: 0.3em;

          .icon-email,
          .icon-money,
          .icon-bank {
            display: inline-block;
            margin-right: 10px;
            color: $BASE_COLOR_11;
            background-color: $BASE_COLOR_10;
            padding: 0.6em;
            border-radius: 50%;
          }

          .text-icon {
            font-size: 0.9em;
            color: $BASE_COLOR_6;
            display: inline;
          }
        }
      }

      .ctn-info-footer {
        margin-top: 2em;

        .text {
          font-size: 1em;
        }
      }
    }

    .ant-modal-footer {
      padding-bottom: 2em;
      border-top: none;
      text-align: center;

      .display-none {
        display: none;
      }
    }
  }
}
