@import '../../../styles/_settings.scss';

.entity-selection-header {
  h1 {
    color: $COLOR_TITLE;
  }

  .tooltip-info {
    color: $COLOR_TOOLTIP;
    cursor: pointer;
  }
}
