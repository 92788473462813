@import '../../../styles/_settings.scss';

.summary-header {
  h1 {
    color: $COLOR_TITLE;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 1.3rem;
  }
}
