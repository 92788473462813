@import '../../../styles/_settings.scss';

.login-header {
  h1 {
    color: $COLOR_TITLE;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 1.3rem;
  }

  .bank-ctn {
    font-size: 1.2rem;
    color: $COLOR_BLACK;

    .bank-icon {
      margin-right: 5px;
      height: 2.5rem;
    }
  }
}

.login-ctn {
  padding: 1rem 0 2rem 0;

  .credentials-help {
    color: $COLOR_PRIMARY;
    text-decoration: underline;
    cursor: pointer;
  }
}
