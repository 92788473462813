@import '../../../../styles/_settings.scss';

div.entity-selection-item {
  cursor: pointer;

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &.active {
    border-color: $COLOR_PRIMARY;
  }

  border-radius: 0.5rem;
  height: 100%;

  .ant-card-body {
    text-align: center;
    padding: 16px 0;

    .icon-ctn {
      position: absolute;
      top: 5px;
      right: 5px;

      span {
        font-size: 1.4rem;

        &.active {
          color: $COLOR_PRIMARY;
        }
      }
    }
  }
}
