@import '../../styles/_settings.scss';

#steps-content {
  padding: 2rem 0;

  h1 {
    font-weight: 600;
  }
}

#steps-action {
  .prev {
    cursor: pointer;
    margin-right: 1rem;
    border-radius: 5px;
    color: $COLOR_PRIMARY;
    background-color: $COLOR_WHITE;
    border-color: $COLOR_PRIMARY;

    span {
      padding-top: 4px;
    }
  }

  .next {
    border-radius: 5px;
    color: $COLOR_WHITE;
    background-color: $COLOR_PRIMARY;
    border-color: $COLOR_PRIMARY;

    &.ant-btn[disabled] {
      color: $COLOR_TEXT_DISABLED;
      background: $COLOR_WHITE;
      border-color: $COLOR_PRIMARY;
    }
  }
}

.ctn-web-site {
  margin-top: 1.5rem;

  .ant-steps-item-active {
    .ant-steps-item-icon {
      background-color: $COLOR_STEPS;
      border-color: $COLOR_STEPS;

      span {
        color: $COLOR_WHITE;
      }
    }

    .ant-steps-item-title {
      color: $COLOR_GREY;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: $COLOR_STEPS;
      border-color: $COLOR_STEPS;

      span {
        color: $COLOR_WHITE;
      }
    }

    .ant-steps-item-title {
      color: $COLOR_GREY;

      &::after {
        background-color: $COLOR_PRIMARY;
      }
    }
  }
}

#app-logo {
  width: $WIDTH_LOGO_HEADER;
  float: right;
}
