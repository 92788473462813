@import '../../../../styles/_settings.scss';

#perfonal-info-form {
  .pi-form-ctn {
    padding: 1.5rem 0 0 0;

    > .title {
      color: $COLOR_BLACK;
    }

    .display-info {
      > .title {
        color: $COLOR_BLACK;
      }
    }

    .input-phone {
      input {
        width: 250px;
      }
    }
  }

  .ant-form-item-label {
    padding: 0;

    label {
      color: $COLOR_BLACK;
      height: 30px;
    }
  }

  .ant-select,
  input {
    width: 300px;
  }

  .ant-input-password {
    width: 300px;
  }
}
