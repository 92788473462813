@import './styles/_settings.scss';

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ctn-private-site {
  img {
    width: 100%;
    height: 100%;
  }
}

.ctn-web-site {
  padding: 3rem 22em;

  @media screen and (max-width: $break-sm) {
    padding: 1rem 1rem 3rem 1rem;
  }

  @media screen and (min-width: $break-sm) and (max-width: $break-md) {
    padding: 1rem 2rem 3rem 2rem;
  }

  @media screen and (min-width: $break-md) and (max-width: $break-lg) {
    padding: 2rem 4rem;
  }

  @media screen and (min-width: $break-lg) and (max-width: $break-xxl) {
    padding: 2rem 10rem;
  }
}
