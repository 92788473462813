@import '../../../../styles/_settings.scss';

.display-ctn {
  padding: 1rem 0;

  > .title {
    color: $COLOR_BLACK;
  }

  .display-info {
    > .title {
      color: $COLOR_BLACK;
      font-size: 1rem;
      font-weight: 600;
    }

    > .subtitle {
      font-size: 1.1rem;
    }
  }
}
