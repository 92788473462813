@import '../../../styles/_settings.scss';

.product-selection-header {
  h1 {
    color: $COLOR_TITLE;
    margin-bottom: 0;
  }

  .subtitle {
    font-size: 1.3rem;
  }
}

.ctn-product-selection {
  margin-top: 1rem;
}
